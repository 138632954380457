import { combineValidate } from '@validarium/core';
import React, { lazy, memo } from 'react';
import { Message } from '@myci/intl';
import { withReducers } from 'redux-syringe';
import { connect } from 'react-redux';
import { applySpec, compose } from 'ramda';
import { SubscriptionCardsSkeletor, USERS_DICT, UserShape } from '@myci/domain-subscriptions';
import { getUserType, reducer as registration } from '@myci/domain-registration';
import { Grid, Heading, Img, SafeSuspense, Section, Text } from '@myci/ui-components';
import { validations } from '@myci/module-registration';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const RegistrationJourney = lazy(() => import('@myci/module-registration/RegistrationJourney'));
const VerifyEmailForm = lazy(() => import('@myci/module-registration/VerifyEmailForm'));
const Success = lazy(() => import('@myci/module-registration/Success'));
const PersonalInfoForm = lazy(() => import('@myci/module-registration/PersonalInfoForm'));
const Payment = lazy(() => import('@myci/module-registration/Payment'));
const GeneralInfoForm = lazy(() => import('@myci/module-registration/GeneralInfoForm'));

const Registration = ({ userType }) => {
	const { validateGeneralInfo, validatePersonalInfo, validateVerifyEmail } = validations;
	const validate = combineValidate(validateGeneralInfo, validatePersonalInfo, validateVerifyEmail);

	return (
		<PublicLayout
			headerClassName="section--registration"
			header={
				<Section pb={{ xs: 4, lg: 0 }} pt={5}>
					<Grid container>
						<Grid row>
							<Grid
								col={{ xs: 12, lg: 5 }}
								pr={{ md: 0 }}
								offset={{ lg: 2 }}
								order={{ xs: 2, lg: 1 }}
							>
								<Heading align={{ xs: 'center', lg: 'left' }} className="section__heading">
									{userType && <Message {...m[`registrationTitle${USERS_DICT[userType]}`]} />}
								</Heading>
								<Text align={{ xs: 'center', lg: 'left' }} className="text-md" mt={3}>
									<Message {...m.registrationDescription} />
								</Text>
							</Grid>
							<Grid col={{ xs: 12, lg: 5 }} order={{ xs: 1, lg: 2 }} className="text-center">
								<Img
									className="reg-img img-fluid mb-0"
									src={require('@myci/assets/images/reg.png')}
									alt=""
								/>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			<SafeSuspense fallback={<SubscriptionCardsSkeletor />}>
				<RegistrationJourney
					validate={validate}
					renderGeneralInfoForm={GeneralInfoForm}
					renderPayment={Payment}
					renderPersonalInfoForm={PersonalInfoForm}
					renderSuccess={Success}
					renderVerifyEmailForm={VerifyEmailForm}
				/>
			</SafeSuspense>
		</PublicLayout>
	);
};

Registration.propTypes = {
	userType: UserShape,
};

export default compose(
	withReducers({ registration }, { isGlobal: true }),
	connect(applySpec({ userType: getUserType }), null),
	memo
)(Registration);
